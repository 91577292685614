<template>
	<el-card v-loading="submitting" class="resumo-venda">
		<h2>Resumo</h2>
		<h3>Venda</h3>
		<div class="resumo-venda__venda">
			<div class="label">Compra</div>
			<div class="value">{{ $n(details.amount, 'currency') }}</div>
			<div v-if="details.downPaymentSum > 0" class="label">Entrada</div>
			<div
				v-if="details.downPaymentSum > 0"
				class="value"
			>- {{ $n(details.downPaymentSum, 'currency') }}</div>
			<div class="label --last">Financiar</div>
			<div class="value --last">{{ $n(details.amount - details.downPaymentSum, 'currency') }}</div>
		</div>
		<br />
		<hr />
		<h3>Financiamento</h3>
		<section class="plan-wrapper">
			<div>
				<h4>{{ details && details.plan ? details.plan.name : '' }}</h4>
				<h4>{{ details ? details.installments : '0' }}x</h4>
			</div>
			<div>
				<p>
					Entrada:
					<strong>{{ $n(details.downPaymentSum, "currency") }}</strong>
				</p>
				<p>
					Juros
					<strong>{{ details.plan.interest }}%</strong>
				</p>
			</div>
		</section>
		<el-table :data="installments.installments" size="mini">
			<el-table-column prop="number" label="Parcela" width="80px" />
			<el-table-column :formatter="handleInstallmentsDate" align="center" label="Vencimento" />
			<el-table-column
				:formatter="handleInstallmentsMoney"
				prop="amount"
				width="120"
				align="right"
				label="Valor"
			/>
		</el-table>
		<div class="resumo-venda__financiamento">
			<div class="label --last">Total:</div>
			<div class="value --bigger --last">{{ $n(installments.total_amount, 'currency') }}</div>
		</div>
		<div class="summary-buttons-wrapper">
			<el-button
				:loading="submitting"
				:disabled="submitting || !isSaleValid"
				round
				size="small"
				@click="$router.go(-1)"
			>Corrigir</el-button>
			<el-button
				:loading="submitting"
				:disabled="submitting || !isSaleValid"
				type="primary"
				round
				size="small"
				@click="handleSaleConfirmation"
			>Confirmar</el-button>
		</div>
	</el-card>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from "moment";

import toMoney from "@/helpers/toMoney";

export default {
	data() {
		return {
			submitting: false,
			hideInstallments: true,
			saleDownPaymentLimit: 0,
			activePlanID: undefined,
			activeInstallments: undefined
		};
	},
	computed: {
		...mapGetters({
			client: types.CLIENT_DETAILS,
			details: types.SALE_DETAILS,
			installments: types.SALE_INSTALLMENTS,
			store_info: types.STORE_INFO
		}),
		firstInstallment() {
			return this.installments.installments[0];
		},
		installmentsRest() {
			const all = [...this.installments.installments];
			all.shift();
			return all;
		},
		isSaleValid() {
			const rule1 = this.saleDownPaymentLimit
				? this.details.downPaymentSum >=
					(this.saleDownPaymentLimit / 100) * this.details.amount
				: true;
			return rule1;
		}
	},
	mounted() {
		this.activePlanID = this.details.plan.id;
		this.activeInstallments = this.details.installments;
	},
	methods: {
		handleInstallmentsMoney(row) {
			if (row.amount > 0) {
				return toMoney(row.amount);
			}
			return 0;
		},
		handleInstallmentsDate(row) {
			const { statement } = row;
			if (statement) {
				return moment(statement.due_date).format("DD/MM/YYYY");
			}
			return "";
		},
		async fetchInstallments({ installments_number, plan_id }) {
			try {
				const response = await this.$store.dispatch(types.SALE_INSTALLMENTS, {
					plan_id,
					sale: {
						installments_number,
						first_due_date: this.details.first_due_date,
						amount: this.details.amount,
						down_payments: this.details.downPayment,
						sale_date: new Date().toISOString().slice(0, 10)
					},
					store_customer_id: this.client.store_customer_id
				});
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}
		},
		async updateSimulation({ id, installments, down_payment }) {
			this.submitting = true;
			this.saleDownPaymentLimit = down_payment;
			this.activePlanID = id;
			this.activeInstallments = installments;
			try {
				await this.fetchInstallments({
					installments_number: installments,
					plan_id: id
				});
				this.submitting = false;
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}
		},
		async handleSaleConfirmation() {
			this.submitting = true;
			try {
				await this.$store.dispatch(types.SALE_DETAILS, {
					valid: true,
					amount: this.details.amount,
					downPaymentSum: this.details.downPaymentSum,
					downPayment: this.details.downPayment,
					description: this.details.description,
					first_due_date: this.details.first_due_date,
					installments: this.activeInstallments,
					plan: this.client.plans.find(it => it.id === this.activePlanID)
				});
				if (this.store_info.sale_token) {
					this.$router.push({ name: "Token da venda" });
					this.submitting = false;
				} else {
					await this.$store.dispatch(types.SALE, {
						sale: {
							status: "1",
							amount: this.details.amount,
							interest: this.details.plan.interest,
							description: this.details.description,
							first_due_date: moment(
								this.details.first_due_date,
								"DD/MM/YYYY"
							).format("YYYY-MM-DD"),
							down_payments: this.details.downPayment,
							installments_number: this.details.installments,
							confirmation_status_id: 5
						},
						user_id: this.client.id,
						plan_id: this.details.plan.id,
						installments: this.installments
					});
					await this.$store.dispatch(types.CLIENT, this.client.id).catch(() => {
						this.$notify.error({
							message: "Erro ao buscar as informações atualizadas do cliente",
							title: "Ops.."
						});
					});
					this.$notify.success({
						message: "Compra realizada com successo!",
						title: "Sucesso!"
					});
					this.$router.push({ name: "Recibo da venda" });
				}
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}
		}
	}
};
</script>

<style scoped lang="scss">
.summary-buttons-wrapper {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.plan-wrapper {
	div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		h4 {
			font-weight: 600;
			margin: 8px 0;
		}
		p {
			font-size: 13px;
			margin-bottom: 20px;
		}
	}
}
.resumo-venda {
	width: 90%;
	max-width: 480px;
	margin: 0 auto 20px auto;
	div {
		color: $preto;
	}
	h3 {
		padding-bottom: 0.25rem;
		margin-top: 1rem;
		font-weight: 500;
		color: #fd9827;
		text-transform: uppercase;
	}

	&__submit {
		width: 100%;
		margin-top: 1rem;
	}

	&__venda {
		display: flex;
		flex-flow: row wrap;
		.label,
		.value {
			flex: 0 50%;
			line-height: 1.55;
			font-size: 1rem;
			font-family: $base-font;
			&.--last {
				margin-top: 1rem;
				font-size: 1.125rem;
				font-weight: bold;
				font-style: normal;
			}
		}
		.label {
			text-align: left;
			font-style: italic;
		}
		.value {
			text-align: right;
		}
	}

	&__financiamento {
		display: flex;
		flex-flow: row wrap;
		.accordion {
			flex: 0 100%;
		}
		.label,
		.value {
			flex: 0 50%;
			line-height: 1.55;
			font-size: 1rem;
			font-family: $base-font;
			&.--last {
				margin-top: 1rem;
				font-size: 1.125rem;
				font-weight: bold;
				font-style: normal;
			}
		}
		.label {
			text-align: left;
			font-style: italic;
		}
		.value {
			text-align: right;
		}

		.installments {
			position: relative;
			flex: 0 100%;
			width: 100%;
			border-bottom: 1px dotted $preto;

			&__collapse {
				position: absolute;
				top: -27px;
				right: -24px;
				width: 28px;
				height: 28px;
				line-height: 29px;
				color: $laranja;
				text-align: center;
				cursor: pointer;
				&:hover {
					color: darken($laranja, 10);
				}
			}

			&__item {
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				width: 100%;
				height: 25.8px;
				opacity: 1;
				will-change: height;
				font-style: italic;
				transition: height 0.3s ease, opacity 0.1s ease-in;

				.number {
					flex: 1;
					font-size: 1rem;
					text-align: left;
				}
				.date {
					flex: 1;
					font-size: 1rem;
					text-align: left;
				}
				.value {
					flex: 1;
					font-size: 1rem;
					text-align: right;
					font-style: normal;
				}
			}

			transition: height 0.3s ease;
			will-change: height;

			&.--collapsed {
				height: 0;
				border-bottom: none;
				.installments__item {
					height: 0;
					opacity: 0;
					transition: none;
				}
			}
		}
	}
}
</style>
