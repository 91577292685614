<template>
  <div class="completar-cadastro">
    <el-alert
      :closable="false"
      class="completar-cadastro__alert"
      title="Cadastro incompleto."
      description="Clique aqui para completar o cadastro."
      type="warning"
      show-icon
      @click.native="show = true"/>
    <el-dialog
      :visible.sync="show"
      title="Completar cadastro">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-position="left"
        label-width="130px"
        @submit.prevent.native="e => e.preventDefault()">
        <el-form-item
          label="Nome completo"
          prop="name"
          required>
          <el-input
            v-model="ruleForm.name"
            size="mini" />
        </el-form-item>
        <el-form-item
          label="Celular"
          prop="phone">
          <el-input
            v-mask="[ '## ####-####', '## #####-####' ]"
            id="phoneInput"
            v-model="ruleForm.phone"
            size="mini"
            placeholder="11 99999-9999"/>
        </el-form-item>
        <el-form-item
          label="CPF"
          prop="cpf">
          <el-input
            v-mask="'###.###.###-##'"
            v-model="ruleForm.cpf"
            size="mini" />
        </el-form-item>
        <div class="form-actions">
          <el-button
            :loading="isSubmitting"
            type="primary"
            native-type="submit"
            @click="validateForm('ruleForm')">
            Salvar
          </el-button>
          <el-button
            :disabled="isSubmitting"
            @click="show = false">
            Cancelar
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import randomCPF from "@/helpers/randomCPF";
import phone from "@/helpers/phone";
import isCPF from "@/helpers/isCPF";

export default {
  data() {
    const validateCPF = (rule, value, cb) => {
      if (!value) {
        return cb();
      }

      if (!isCPF(value)) {
        return cb(new Error("CPF inválido"));
      }
      return cb();
    };
    return {
      show: false,
      isSubmitting: false,
      egg: new this.$egg(),
      ruleForm: {
        cpf: "",
        name: "",
        phone: ""
      },
      rules: {
        cpf: [{ validator: validateCPF, trigger: "change" }],
        name: [
          { required: true, message: "Campo obrigatório", trigger: "blur" }
        ]
      }
    };
  },
  computed: mapGetters({
    client: types.CLIENT_DETAILS
  }),
  created() {
    this.egg
      .addCode("left,down,right,r", () => {
        this.ruleForm.cpf = randomCPF();
      })
      .listen();
  },
  mounted() {
    this.ruleForm = {
      ...this.ruleForm,
      ...this.client
    };
  },
  methods: {
    validateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    async submitForm() {
      this.isSubmitting = true;
      const payload = this.generatePayload();
      try {
        await this.$store.dispatch(types.CLIENT_UPDATE, payload);
        await this.$store.dispatch(types.CLIENT, this.client.id);
        this.show = false;
        this.$notify.success({
          title: "Sucesso",
          message: "Dados do cliente atualizados!"
        });
      } catch(error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.isSubmitting = false;
    },
    generatePayload() {
      return {
        user: {
          cpf: this.ruleForm.cpf.replace(/\D/gi, ""),
          name: this.ruleForm.name,
          phone: phone.unformat(this.ruleForm.phone)
        }
      };
    }
  }
};
</script>

<style lang="scss">
.completar-cadastro {
  &__alert {
    margin-bottom: 1rem;
    cursor: pointer;
    &:hover {
      @include box-shadow(1);

      .el-alert__description {
        text-decoration: underline;
      }
    }
  }
}
</style>
