<template>
  <el-card class="token-venda">
    <h2>Token</h2>
    <h3>Entre com o código de verificação do cliente</h3>
    <p>
      <small>O cliente pode ver o código de confirmação através do app</small>
    </p>

    <el-input
      v-mask="'######'"
      ref="token"
      v-model="userToken"
      autofocus
      placeholder="TOKEN"
      class="token"
    >
      <el-button
        slot="append"
        :loading="isProcessingSale"
        :disabled="userToken.length !== 6 || isProcessingSale"
        @click="handleSaleConfirmation(false)"
      >Confirmar</el-button>
    </el-input>
    <div class="token-timer">
      <div
        :style="{
          width: ((countDown / 30) * 100).toFixed(2) + '%'
        }"
        class="bar"
      />
      <span>{{ countDown }}s</span>
    </div>
    <br />
    <el-button
      size="mini"
      type="success"
      @click="showSMSModal = true"
    >Enviar token via SMS{{ maskedPhone }}?</el-button>
    <template v-if="storeInfo.legacy_mode">
      <br />
      <br />
      <el-button type="danger" size="mini" @click="showFallback = true">Não consigo validar o token</el-button>
    </template>
    <el-dialog :width="$isMobile ? '100%' : '50%'" :visible.sync="showSMSModal">
      <h2>Enviar token via SMS</h2>
      <br />
      <el-alert
        :closable="false"
        type="warning"
        title
        description="Espere 15 minutos para enviar outro token para um mesmo número"
      />
      <br />
      <el-row>
        <el-button
          :loading="sendingSMS"
          :disabled="targetNumber.length < 10 || sendingSMS || counter > 0"
          type="primary"
          @click="sendSMS"
        >Enviar {{ counter > 0 ? `novamente em ${counter}` : '' }}</el-button>
      </el-row>
    </el-dialog>
    <el-dialog :width="$isMobile ? '100%' : '50%'" :visible.sync="showFallback">
      <div class="dialog-content--wrapper">
        <h2>Venda sem token</h2>
        <br />
        <el-radio
          v-model="confirmationID"
          :label="3"
          style="width: 300px;"
          border
        >O Cliente não tem acesso App.</el-radio>
        <br />
        <el-radio
          v-model="confirmationID"
          :label="4"
          style="width: 300px;"
          border
        >O Cliente não está na loja.</el-radio>
        <br />
        <br />
        <el-button
          :loading="isProcessingSale"
          :disabled="isProcessingSale"
          style="width: 300px"
          type="primary"
          round
          @click="handleSaleConfirmation(true)"
        >Confirmar</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      userToken: "",
      sendingSMS: false,
      showSMSModal: false,
      showFallback: false,
      targetNumber: "",
      isProcessingSale: false,
      countDown: 0,
      confirmationID: 4,
      counter: 0
    };
  },
  computed: {
    ...mapGetters({
      storeInfo: types.STORE_INFO,
      details: types.SALE_DETAILS,
      client: types.CLIENT_DETAILS,
      store: types.AUTH_ACTIVE_STORE,
      installments: types.SALE_INSTALLMENTS
    }),
    maskedPhone() {
      return this.targetNumber
        ? this.targetNumber
            .replace(/\D/g, "")
            .split("")
            .reduce((masked, number, index, raw) => {
              masked += index < raw.length - 2 ? `•` : number + ``;
              return masked;
            }, ` para `)
        : "";
    }
  },
  watch: {
    counter: function(value) {
      if (value <= 0) {
        clearInterval(this.tick);
      }
    }
  },
  mounted() {
    this.$refs.token.focus();
    this.targetNumber = this.client.phone
      ? this.client.phone.replace("+55", "")
      : "";
    setInterval(this.timer, 1000);
  },
  methods: {
    timer() {
      const epoch = Math.round(new Date().getTime() / 1000.0);
      this.countDown = 300 - (epoch % 300);
    },
    tick() {
      this.counter = this.counter - 1;
    },
    async sendSMS() {
      try {
        if (this.counter <= 0) {
          this.counter = 180;
          setInterval(this.tick, 1000);
          this.sendingSMS = true;
          await this.$store.dispatch(types.STORE_SEND_SMS_SALE, {
            userID: this.client.id,
            phone: "55" + this.targetNumber
          });
          this.sendingSMS = false;
          this.showSMSModal = false;
          this.$notify.success({
            title: "SMS Enviado!",
            message: "Peça para o cliente consultar o celular"
          });
        }
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async handleSaleConfirmation(fallback = false) {
      if (this.isProcessingSale) return false;
      this.isProcessingSale = true;
      if (!fallback) {
        try {
          const valid = await this.$store.dispatch(types.CLIENT_TOKEN_SALE, {
            token: this.userToken,
            userID: this.client.id
          });
          if (!valid) {
            this.isProcessingSale = false;
            return this.$notify.error({
              message: "Token inválido!"
            });
          }
        } catch (error) {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
          this.isProcessingSale = false;
        }
        
      }
      try {
        await this.$store.dispatch(types.SALE, {
          sale: {
            status: "1",
            amount: this.details.amount,
            interest: this.details.plan.interest,
            description: this.details.description,
            first_due_date: moment(
              this.details.first_due_date,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD"),
            down_payments: this.details.downPayment,
            installments_number: this.details.installments,
            confirmation_status_id: fallback ? this.confirmationID : 1
          },
          user_id: this.client.id,
          plan_id: this.details.plan.id,
          installments: this.installments
        });
        await this.$store.dispatch(types.CLIENT, this.client.id).catch(() => {
          this.$notify.error({
            message: "Erro ao buscar as informações atualizadas do cliente",
            title: "Ops.."
          });
        });
        this.$notify.success({
          message: "Compra realizada com successo!",
          title: "Sucesso!"
        });
        this.$router.push({ name: "Recibo da venda" });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.isProcessingSale = false;
    }
  }
};
</script>
<style lang="scss">
.token-venda {
  width: 90%;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;

  div {
    color: $preto;
  }

  h2 {
    text-align: center;
  }

  h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
    color: #fd9827;
    text-align: center;
  }
  p {
    margin-bottom: 0.5rem;
    font-style: italic;
    text-align: center;
  }
  .token {
    max-width: 250px;
    margin: 0 auto;
  }
  .token-timer {
    display: flex;
    flex-flow: row wrap;
    width: 100px;
    height: 30px;
    margin: 1rem auto 0;
    span {
      flex: 0 100%;
      height: 20px;
      font-size: 11px;
      line-height: 20px;
    }
    .bar {
      position: relative;
      min-width: 1px;
      height: 8px;
      // background: rgba($preto, 0.25);
      background: #0cf9cd;
      border-radius: 2px;
      &::before {
        position: absolute;
        top: -1px;
        left: -1px;
        width: 102px;
        min-width: 1px;
        height: 10px;
        content: "";
        border: 1px solid $preto;
        border-radius: 2px;
      }
    }
  }
}
.dialog-content--wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .el-radio,
  .is-checked {
    margin: 0;
  }
  .el-radio.is-bordered + .el-radio.is-bordered {
    margin: 0;
  }
}
</style>
