<template>
  <el-alert
    :closable="false"
    class="realizar-consulta"
    title="Não verificado."
    description="Clique aqui para realizar a consulta."
    type="warning"
    show-icon
    @click.native="handleNavigation"/>
</template>

<script>
export default {
  methods: {
    handleNavigation() {
      this.$router.push({ name: "Consulta Neopag - CPF" });
    }
  }
};
</script>

<style lang="scss">
.realizar-consulta {
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    @include box-shadow(1);

    .el-alert__description {
      text-decoration: underline;
    }
  }
}
</style>
