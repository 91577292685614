<template>
  <div class="plans-combo">
    <template v-if="this.plans.length === 0">
      <span class="input-label centered"
        >Não há planos disponíveis para o Cliente</span
      >
    </template>
    <section class="plans-wrapper">
      <el-button
        v-for="item in installments_plans"
        :key="item.id"
        :type="item.selected ? 'primary' : 'secondary'"
        @click="handlePlanChange(item)"
        >{{ item.name }}</el-button
      >
    </section>
    <section class="installments-wrapper">
      <el-button
        v-for="item in installments"
        :key="item.installment"
        :type="item.selected ? 'primary' : 'secondary'"
        @click="handleInstallmentClick(item)"
        >{{ item.installment }}x</el-button
      >
    </section>
    <div class="plans-combo__info">
      <span>
        Juros:
        <b>{{ currentPlan.interest }}%</b>
      </span>
      <span v-if="currentPlan.down_payment && currentPlan.down_payment >= 0">
        Entrada:
        <b>{{
          currentPlan.down_payment ? currentPlan.down_payment + "%" : "opcional"
        }}</b>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters, install } from "vuex";
import * as types from "@/store/types";
export default {
  props: {
    plans: {
      type: Array,
      required: true,
    },
    selectedPlan: {
      type: [Object, Boolean],
      default: () => false,
    },
    initial: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentObject: {
        id: undefined,
        installments: undefined,
        down_payment: undefined,
      },
      currentPlan: {
        down_payment: 0,
        grace_period: 0,
        interest: 0,
      },
      currentInstallments: [],
      installments: [],
      installments_plans: [],
    };
  },
  computed: {
    ...mapGetters({
      details: types.SALE_DETAILS,
    }),
  },
  mounted() {
    if (this.plans && Array.isArray(this.plans)) {
      this.handlePlanChange(this.plans[0], true);
    }
  },
  methods: {
    handleInstallmentClick(installment) {
      const { down_payment, grace_period, interest } = installment.plan;
      const installments = this.installments.map((item) => ({
        ...item,
        selected: item.installment === installment.installment,
      }));
      this.installments = installments;
      this.currentPlan = {
        down_payment,
        grace_period,
        interest,
      };
      this.$emit("onInstallmentSelect", installment);
    },
    handlePlanChange(plan, forceDetails = false) {
      if (forceDetails && typeof this.details.plan != "undefined") {
        const installments_plans = this.plans.map((item) => ({
          ...item,
          selected: this.details.plan
            ? item.id === this.details.plan.id
            : item.id === plan.id,
        }));
        this.installments_plans = installments_plans;
        const { from, to } = this.details.plan.installment;
        const { down_payment, grace_period, interest } = this.details.plan;
        const installments = [];
        for (let i = from; i <= to; i++) {
          const item = {
            plan: { ...this.details.plan },
            selected: this.details.installments === i,
            installment: i,
          };
          installments.push(item);
          this.installments = installments;
          this.currentPlan = {
            down_payment,
            grace_period,
            interest,
          };
          this.$emit("onInstallmentSelect", {
            installment: this.details.installments,
            plan: this.details.plan,
          });
        }
      } else {
        if (plan) {
          const { down_payment, grace_period, interest } = plan;
          const { from, to } = plan.installment;
          const installments_plans = this.plans.map((item) => ({
            ...item,
            selected: item.id === plan.id,
          }));
          this.installments_plans = installments_plans;
          const installments = [];
          for (let i = from; i <= to; i++) {
            const item = {
              plan: { ...plan },
              selected: false,
              installment: i,
            };
            installments.push(item);
          }
          if (installments) {
            installments[0].selected = true;
            this.installments = installments;
          }
          this.currentPlan = {
            down_payment,
            grace_period,
            interest,
          };
          this.$emit("onInstallmentSelect", installments[0]);
        }
      }
    },
    handleInstallmentsChange() {
      this.$emit("input", this.currentObject);
    },
    fillArray(start, end) {
      return Array.from(Array(end - start + 1).keys()).map(
        (it, index) => start + index
      );
    },
  },
};
</script>

<style lang="scss">
.plans-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  .el-button {
    text-overflow: ellipsis;
    overflow: hidden;
    span {
      text-overflow: ellipsis;
    }
  }
  .el-button + .el-button {
    margin: 0;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.installments-wrapper {
  margin-top: 20px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  .el-button + .el-button {
    margin: 0;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.plans-combo {
  margin: 1rem 0;
  display: flex;
  flex-flow: row wrap;
  .el-select,
  .el-select * {
    margin-bottom: 0 !important;
  }
  &__id {
    flex: 1;
  }
  &__installments {
    flex: 0 80px;
    margin-left: 1rem;
    .el-input__inner {
      text-align: right;
    }
  }
  &__info {
    flex: 0 100%;
    line-height: 46px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 14px;
    opacity: 0.85;
    @include small-down {
      font-size: 12px;
    }
  }
}
</style>
