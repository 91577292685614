<template>
  <div class="payment-combo">
    <template v-for="(obj, index) in currentArray">
      <div
        :class="inputClass"
        :key="'input' + index">
        <div class="el-input-group__prepend">
          <el-select
            slot="prepend"
            :default-first-option="true"
            v-model="obj.payment_method"
            value-key="id"
            placeholder="Pgto."
            class="payment-combo-method"
            @change="handleChange">
            <el-option
              v-for="method in methods"
              :key="method.value"
              :label="method.label"
              :value="method.value" />
          </el-select>
        </div>
        <money
          v-model="obj.amount"
          v-bind="$money"
          class="el-input__inner"
          @input="handleChange"/>
      </div>
      <el-button
        v-if="index === 0"
        :key="'more' + index"
        class="more-button"
        type="text"
        icon="el-icon-circle-plus"
        @click="prepend"/>
      <el-button
        v-else
        :key="'delete' + index"
        class="delete-button"
        type="text"
        icon="el-icon-delete"
        @click="remove(index)"/>
    </template>
  </div>
</template>
<script>
import { Money } from "v-money";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  components: {
    Money
  },
  props: {
    avaiableMethods: {
      type: Array,
      default: () => ["money", "debit", "cupon", "credit", "check", "predated"]
    },
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      inputClass:
        "main-input el-input el-input-group el-input-group--append el-input-group--prepend",
      currentArray: [{ amount: 0, payment_method: "money" }],
      methods: [
        {
          value: "money",
          label: "Dinheiro"
        },
        {
          value: "credit",
          label: "Cartão de crédito"
        },
        {
          value: "cupon",
          label: "Cupom NEOPAG"
        },
        {
          value: "debit",
          label: "Cartão de débito"
        },
        {
          value: "check",
          label: "Cheque"
        },
        {
          value: "predated",
          label: "Cheque pré-datado"
        }
      ].filter(it => this.avaiableMethods.indexOf(it.value) >= 0)
    };
  },
  computed: {
    ...mapGetters({
      details: types.SALE_DETAILS,
    }),
  },
  watch: {
    value(val) {
      if (val.length) this.currentArray = val;
    }
  },
  mounted() {
    if (this.details.downPayment && this.details.downPayment.length > 0) {
      this.currentArray = this.details.downPayment;
      this.$emit("input", this.details.downPayment);
    }
  },
  methods: {
    prepend() {
      this.currentArray.unshift({
        amount: 0,
        payment_method: "money"
      });
      this.handleChange();
    },
    remove(index) {
      this.currentArray.splice(index, 1);
      this.handleChange();
    },
    handleChange() {
      this.$emit("input", this.currentArray);
    }
  }
};
</script>

<style lang="scss">
.payment-combo {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  .main-input {
    width: calc(100% - 24px);
    margin-bottom: 0.5rem;
  }
  .more-button,
  .delete-button {
    float: right;
    height: 42px;
  }
  .el-input-group__prepend,
  .el-input-group__append {
    background-color: #fff;
  }
  .el-select .el-input {
    width: 100px;
  }
  .v-money.el-input__inner {
    height: 42px;
    text-align: right;
  }
  .installments-input {
    width: 80px;
    .el-input__inner {
      border: none;
    }
  }
  .el-input-group__append {
    padding: 0;
  }
  .payment-combo-method input {
    font-size: 0.75rem !important;
  }
}
</style>
