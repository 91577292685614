<template>
  <escolher-cliente
    v-if="!isClientSet"
    title="Venda"
    show-consult-suggestion />
  <sale-wizard v-else />
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import EscolherCliente from "@/containers/EscolherCliente";
import SaleWizard from "@/containers/Sale/Wizard";

export default {
  components: {
    EscolherCliente,
    SaleWizard
  },
  computed: mapGetters({
    isClientSet: types.CLIENT_IS_SET
  })
};
</script>
