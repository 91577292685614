<template>
  <div>
    <el-alert
      :closable="false"
      class="client-alert"
      type="warning"
      show-icon
      style="margin-bottom: 16px;"
      title=""
    >
      <div class="confirm-phone-alert-content">
        <h2>Atenção</h2>
        <p>O número de celular desse cliente ainda não foi confirmado!</p>
        <el-button type="primary" size="mini" @click="showModal = true">Confirmar número</el-button>
      </div>
    </el-alert>
    <el-dialog :visible.sync="showModal" :before-close="onModalClose" title="Atenção">
      <div style="text-align: center;">
        <div v-if="!showToken">
          <p>Um código de confirmação será enviado para o seguinte número:</p>
          <h1
            v-if="!editPhone"
            style="text-align: center; margin: 16px 32px; font-weight: 500; letter-spacing: 1px;"
          >{{ user.phone ? user.phone.replace('+55', '') : '' | toPhone }}</h1>
          <el-input
            v-mask="'(##) #####-####'"
            v-else
            v-model="phone"
            style="margin: 16px 0;"
            placeholder="Número de celular"
          />
          <el-button type="success" @click="handlePhoneConfirm">Enviar código</el-button>
          <el-button type="primary" @click="onChangePhone">Alterar número</el-button>
        </div>
        <div v-else>
          <p>Enviamos um código de confirmação para o número {{ phone ? phone.replace('+55', '') : '' | toPhone }} insira o código no campo abaixo para continuar.</p>
          <el-input
            v-mask="'######'"
            v-model="tokenCode"
            class="token-input"
            placeholder="Código Token"
          />
          <el-button
            v-loading="loading"
            type="success"
            @click="handleTokenConfirmation"
          >Validar Código</el-button>
          <el-button
            v-loading="loading"
            :disabled="timeToResend > 0"
            type="danger"
            @click="resendCode"
          >Enviar novamente {{ timeToResend > 0 ? `${timeToResend}s` : '' }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import phoneHelper from "@/helpers/phone";

export default {
  filters: {
    toPhone: phoneHelper.format
  },
  data() {
    return {
      showModal: false,
      showToken: false,
      loading: false,
      tokenCode: "",
      phone: "",
      editPhone: false,
      timeToResend: 0
    };
  },
  computed: {
    ...mapGetters({
      user: types.CLIENT_DETAILS
    })
  },
  methods: {
    async onModalClose() {
      this.showModal = false;
      this.showToken = false;
      this.editPhone = false;
      if (this.$route.name === "Detalhes da venda") {
        this.$router.push("/venda");
      }
    },
    onChangePhone() {
      this.phone = phoneHelper.unformat(
        this.user.phone ? this.user.phone.replace("+55", "") : ""
      );
      this.editPhone = true;
    },
    async handlePhoneConfirm() {
      this.showToken = true;
      if (!this.editPhone) {
        this.phone = this.user.phone ? this.user.phone.replace("+55", "") : "";
      }
      try {
        await this.$store.dispatch(types.CLIENT_CONFIRM_PHONE, {
          phone: `55${phoneHelper.unformat(this.phone)}`,
          userId: this.user.id
        });
        this.$notify.success({ title: "Código enviado!" });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    resendCode() {
      if (this.timeToResend <= 0) {
        this.timeToResend = 180;
        setInterval(() => {
          this.timeToResend = this.timeToResend - 1;
        }, 1000);
        this.handlePhoneConfirm();
      }
    },
    async handleTokenConfirmation() {
      this.loading = true;
      try {
        await this.$store.dispatch(types.CLIENT_TOKEN, {
          token: this.tokenCode,
          userID: this.user.id
        });
        await this.$store.dispatch(types.CLIENT_UPDATE, {
          user: {
            id: this.user.id,
            phone_confirmed: true,
            phone: `+55${phoneHelper.unformat(this.phone)}`
          }
        });
        this.loading = false;
        this.onModalClose();
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        } else if (error.status === 404) {
          this.$notify.error("Token Inválido");
        } else {
          this.$notify.error({ title: error.message });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.token-input {
  display: block;
  width: 300px;
  margin: 16px auto;
}
.confirm-phone-alert-content {
  h2 {
    color: #e6a23c;
    font-weight: 700;
  }
  p {
    margin: 8px 0;
    line-height: 1.2;
  }
  .el-button {
    width: 100%;
  }
}
</style>
