<template>
  <el-card class="sale-receipt">
    <i class="graphic el-icon-success" />
    <h2>Compra realizada</h2>
    <h4>
      Confirmamos a venda de
      <br />
      <strong>{{ $n(details.amount, 'currency') }}</strong>
    </h4>
    <br />
    <h4>
      O identificador da sua venda é:
      <strong>
        #{{moreDetails.identifier}}
      </strong>
    </h4>
    <div class="align-center">
      <el-button class="submit-button" round type="primary" @click="newSale">Nova venda</el-button>
      <el-button round icon="el-icon-printer" @click="showReceipt()">Ver recibo</el-button>
      <el-button v-if="this.moreDetails.ccb !== null" round icon="el-icon-warning" type="success" @click="downloadCCB()">CCB</el-button>
    </div>
  </el-card>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      details: types.SALE_DETAILS,
      moreDetails: types.SALE_MORE_DETAILS,
      store: types.STORE_INFO,
      installments: types.SALE_INSTALLMENTS,
      moreDetails: types.SALE_MORE_DETAILS
    })
  },
  methods: {

    downloadCCB() {

      //console.trace(this.moreDetails.ccb);
      //window.alert(this.moreDetails.ccb);
      window.open(this.moreDetails.ccb, "_blank");
    },

    async newSale() {
      await this.$store.dispatch(types.SALE_RESET);
      await this.$store.dispatch(types.CLIENT_RESET);
      this.$router.push({ name: "Detalhes da venda" });
    },
    showReceipt() {
      this.$router.push({
        name: "Imprimir recibo da venda"
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "./src/styles/theme.scss";

.sale-receipt {
  width: 90%;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;

  .graphic {
    margin-bottom: 1rem;
    font-size: 3rem;
    color: darken($verde, 10);
  }
  h2 {
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 400;
    line-height: 1.55;
  }
  .align-center {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-top: 1.5rem;
    text-align: center;
    .share-button,
    .submit-button {
      flex: 0 auto;
    }
  }
}
</style>
