const random = n => Math.round(Math.random() * n);
const mod = (dd, dv) => Math.round(dd - Math.floor(dd / dv) * dv);

export default () => {
  const n1 = random(9);
  const n2 = random(9);
  const n3 = random(9);
  const n4 = random(9);
  const n5 = random(9);
  const n6 = random(9);
  const n7 = random(9);
  const n8 = random(9);
  const n9 = random(9);

  let d1 =
    n9 * 2 +
    n8 * 3 +
    n7 * 4 +
    n6 * 5 +
    n5 * 6 +
    n4 * 7 +
    n3 * 8 +
    n2 * 9 +
    n1 * 10;

  d1 = 11 - mod(d1, 11);

  if (d1 >= 10) d1 = 0;

  let d2 =
    d1 * 2 +
    n9 * 3 +
    n8 * 4 +
    n7 * 5 +
    n6 * 6 +
    n5 * 7 +
    n4 * 8 +
    n3 * 9 +
    n2 * 10 +
    n1 * 11;

  d2 = 11 - mod(d2, 11);

  if (d2 >= 10) d2 = 0;

  return [n1, n2, n3, n4, n5, n6, n7, n8, n9, d1, d2].join("");
};
