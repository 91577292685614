<template>
  <el-card class="detalhes-venda">

    <span class="input-label centered">Valor total da venda</span>
    <div class="amount-input">
      <money ref="input" v-model="saleAmount" v-bind="$money" class="el-input__inner" />
    </div>

    <span class="input-label centered">
      Plano e parcelas
      <el-popover
        ref="plans"
        :tabindex="-1"
        placement="bottom"
        width="200"
        trigger="hover"
        content="Você poderá simular/alterar o plano e a quantidade de parcelas na próxima tela, antes de confirmar a venda."
      />
      <i
        v-popover:plans
        id="removeFocus"
        :tabindex="-1"
        class="el-icon el-icon-question plans-icon"
      />
    </span>
    <plans-combo :plans="client.plans" @onInstallmentSelect="updatePlanAndInstallments" />
    <span class="input-label centered">
      Primeira parcela
      <el-popover
        ref="plans"
        :tabindex="-1"
        placement="bottom"
        width="200"
        trigger="hover"
        content="Você poderá simular/alterar o plano e a quantidade de parcelas na próxima tela, antes de confirmar a venda."
      />
      <i
        v-popover:plans
        id="removeFocus"
        :tabindex="-1"
        class="el-icon el-icon-question plans-icon"
      />
    </span>
    <due-date-combo :user="client" @onDateSelect="val => first_due_date = val" />
    <div class="inline-switch">
      <span class="input-label">Entrada</span>
      <el-switch
        v-model="showDownPayment"
        class="down-payment-switch"
        active-text="Sim"
        inactive-text="Não"
      />
    </div>

    <template v-if="showDownPayment">
      <span class="input-label">Valor de entrada:</span>
      <payment-combo v-model="saleDownPayment" :money="$money" class="detalhes-venda__combo" />
    </template>

    <div class="inline-switch">
      <span class="input-label">Descrição</span>
      <el-switch
        v-model="showDescription"
        class="down-payment-switch"
        active-text="Sim"
        inactive-text="Não"
      />
    </div>
    <el-input
      v-if="showDescription"
      v-model="saleDescription"
      style="resize: vertical; margin-bottom: 1rem;"
      type="textarea"
    />

    <template v-if="insuficientCredit">
      <el-alert
        :closable="false"
        class="client-alert"
        title="Crédito insuficiente"
        description="Tente aumentar o valor da entrada."
        type="error"
        show-icon
      />
      <br />
    </template>

    <confirm-phone-alert v-if="!client.phone_confirmed" />

    <el-button
      :loading="submitting"
      :disabled="!isSaleValid || handlePermissions"
      class="submit-button"
      type="primary"
      round
      size="small"
      @click="handleSubmit"
    >Vender</el-button>
  </el-card>
</template>

<script>
import { Money } from "v-money";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import PlansCombo from "@/components/PlansCombo";
import DueDateCombo from "@/components/DueDateCombo";
import PaymentCombo from "@/components/PaymentCombo";
import RealizarConsulta from "@/containers/RealizarConsulta";
import CompletarCadastro from "@/containers/CompletarCadastro";
import ConfirmPhoneAlert from "@/components/ConfirmPhoneAlert";

export default {
  components: {
    Money,
    PlansCombo,
    DueDateCombo,
    PaymentCombo,
    RealizarConsulta,
    CompletarCadastro,
    ConfirmPhoneAlert
  },
  data() {
    return {
      submitting: false,
      showDownPayment: false,
      showDescription: false,

      saleAmount: 0,
      saleDescription: "",
      salePlan: false,
      saleInstallments: 1,
      saleDownPayment: [],
      saleDownPaymentLimit: 0,
      first_due_date: ""
    };
  },
  watch: {
    saleAmount(val) {
      if (val.length > 3) {
        this.handlePermissions;
      }
    }
  },
  computed: {
    ...mapGetters({
      client: types.CLIENT_DETAILS,
      details: types.SALE_DETAILS,
      auth_details: types.AUTH_ACTIVE_STORE_DETAILS
    }),
    showSanitizeRegister() {
      if (this.client && this.client.neopag_status) {
        return this.client.neopag_status.id === 16;
      }
      return false;
    },
    showVerifyRegister() {
      if (this.client && this.client.store_status) {
        return this.client.store_status.id === 5;
      }
      return false;
    },
    saleDownPaymentSum() {
      return this.saleDownPayment && this.saleDownPayment.length
        ? this.saleDownPayment.map(it => it.amount).reduce((a, b) => a + b)
        : 0;
    },
    saleRealAmount() {
      return this.saleAmount - this.saleDownPaymentSum;
    },
    insuficientCredit() {
      const limit = this.client.current_credit;
      return this.saleRealAmount > limit;
    },
    isSaleValid() {
      const rule1 = this.saleAmount > 0;
      const rule2 = !!this.salePlan;
      const rule3 = !this.insuficientCredit;
      const rule4 = this.saleRealAmount > 0;
      const rule5 = this.saleDownPaymentLimit
        ? this.saleDownPaymentSum >=
          (this.saleDownPaymentLimit / 100) * this.saleAmount
        : true;
      const rule6 = this.saleInstallments > 0;
      return rule1 && rule2 && rule3 && rule4 && rule5 && rule6;
    },
    handlePermissions() {
      if (!this.auth_details || !this.auth_details.permissions) {
        this.$notify.warning(
          "Ops, um erro aconteceu, por favor recarregue a página"
        );
        return true;
      }
      if (
        !this.auth_details.permissions.sale_if_documents_not_sent &&
        this.client.documents_sent === "not_sent"
      ) {
        //vender se o documento foi enviado e o status for diferente de 'not_sent'
        this.$notify.warning("Documento não enviado");
        return true; //desabilita
      } else if (
        !this.auth_details.permissions.sale_if_documents_not_aproved &&
        this.client.documents_sent != "ok"
      ) {
        // vender se o documento foi aprovado e o status for igual a 'ok'
        this.$notify.warning("Documento reprovado");

        return true; //desabilita
      } else if (
        !this.auth_details.permissions.sale_if_address_not_sent &&
        this.client.address_proof_status === "not_sent"
      ) {
        // só vender se o endereço tiver sido enviado e o status for igual a 'ok ou 'sent'
        this.$notify.warning("Endereço não enviado");

        return true; //desabilita
      } else if (
        !this.auth_details.permissions.sale_if_address_not_aproved &&
        this.client.address_proof_status != "ok"
      ) {
        // só vender se o endereço tiver sido aprovado e o status for igual a 'ok'
        this.$notify.warning("Endereço reprovado");

        return true; //desabilita
      } else if (
        !this.auth_details.permissions.sale_if_income_not_sent &&
        this.client.income_proof_status === "not_sent"
      ) {
        // só vender se a renda tiver sido envada e o status for igual a 'ok' ou 'sent'
        this.$notify.warning("Renda não enviada");
        return true; //desabilita
      } else if (
        !this.auth_details.permissions.sale_if_income_not_aproved &&
        this.client.income_proof_status != "ok"
      ) {
        // só vender se a renda tiver sido enviada e o status for igual a 'ok'
        this.$notify.warning("Renda reprovada");
        return true;
      } else if (
        !this.auth_details.permissions.sale_if_phone_not_confirmed &&
        !this.client.phone_confirmed
      ) {
        // só vender se o telefone foi confirmado e o status e a confirmação for 'true'
        this.$notify.warning("Telefone não confirmado");
        return true; //desabilita
      } else {

        //adiciona verificação hardcoded para cliente neopag credito
        if (
            (this.client.address_proof_status === "not_sent" ||
            this.client.address_proof_status != "ok" ||
            this.client.documents_sent != "ok" ||
            !this.client.phone_confirmed)
            &&
            ( this.client.is_neopag_credit )
          ){
            // só vender se o telefone foi confirmado e o status e a confirmação for 'true'
            this.$notify.warning("Endereço, telefone e documentos validados são obrigatórios para esse cliente!");

            return true //desabilita;
          }else{
            return false;
          }

      }
    }
  },
  mounted() {

    /*
    console.trace(this.auth_details);
    console.trace(this.client);
    console.trace(this.details);
    console.trace(this.first_due_date);
    */

    if (this.$refs.input) {
      this.$refs.input.$el.focus();
    }
    this.saleAmount = this.details.amount ? this.details.amount : 0;
    if (this.details.downPayment && this.details.downPayment.length > 0) {
      this.saleDownPayment = this.details.downPayment;
      this.showDownPayment = true;
    }
    if (this.details.description) {
      this.saleDescription = this.details.description;
      this.showDescription = true;
    }
    if (this.details.plan) {
      this.saleDownPaymentLimit = this.details.plan.down_payment;
      this.salePlan = this.details.plan;
    }
    // document.addEventListener("keydown", this.handleKeyboardNavigation);
  },
  beforeDestroy() {
    // document.removeEventListener("keydown", this.handleKeyboardNavigation);
  },
  methods: {
    handleKeyboardNavigation(event) {
      if (event.key === "Enter" && this.isSaleValid) {
        this.handleSubmit();
      }
    },
    updatePlanAndInstallments(installment) {
      this.saleInstallments = installment.installment;
      this.saleDownPaymentLimit = installment.plan.down_payment;
      try {
        if (this.client != undefined && this.client.plans != undefined) {
          this.salePlan = this.client.plans.find(
            it => it.id === installment.plan.id
          );
        } else {
          throw new Error("this.client || this.client.plan is undefined");
        }
      } catch (err) {
        if (this.$raven) {
          this.$raven.captureException(err.message);
        }
        this.$notify.error(
          "Ops, um erro aconteceu. Já estamos trabalhando para resolver esse problema. Não se preocupe."
        );
      }
    },
    async handleSubmit() {
      this.submitting = true;
      try {
        await this.$store.dispatch(types.SALE_DETAILS, {
          valid: true,
          plan: this.salePlan,
          amount: this.saleAmount,
          description: this.saleDescription,
          downPayment: this.saleDownPayment,
          installments: this.saleInstallments,
          downPaymentSum: this.saleDownPaymentSum || 0,
          first_due_date: this.first_due_date
        });
        this.submitting = false;
        this.$router.push({ name: "Sumario da venda" });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.centered {
  text-align: center;
}
.amount-input {
  input {
    margin: 1rem 0;
    background: transparent;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 38px !important;
    font-family: Montserrat;
    font-weight: 700;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    color: $laranja;
    outline: none;
    padding-bottom: 4px;
  }
}
.detalhes-venda {
  width: 90%;
  max-width: 480px;
  // margin: 0 auto 1rem auto;

  &__name {
    margin-bottom: 1rem;
  }

  &__limit {
    margin-bottom: 26px;
  }

  &__combo {
    margin-top: 0;
  }

  .inline-switch {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .plans-icon {
    color: $laranja;
    cursor: pointer;
  }

  .input-label {
    display: block;
    margin-bottom: 0;
    font-size: 0.9375rem;
  }

  .el-switch__label * {
    font-size: 0.9375rem;
  }

  .el-input,
  .el-select {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .submit-button {
    width: 100%;
  }
}
</style>
