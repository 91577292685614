<template>
  <div class="due-dates-wrapper">
    <el-button
      v-for="item in dueDates"
      :type="item.selected ? 'primary' : 'secondary'"
      :key="item.id"
      @click="handleDateClick(item)"
    >{{ item.date }}</el-button>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  props: {
    user: {
      type: [Object, null],
      default: () => {}
    }
  },
  data() {
    return {
      dueDates: []
    };
  },
  computed: {
    ...mapGetters({
      details: types.SALE_DETAILS,
      auth_details: types.AUTH_ACTIVE_STORE_DETAILS
    })
  },
  mounted() {
    if (
      this.user &&
      this.user.user_statements &&
      this.user.user_statements.closed_statement
    ) {
      const dates = [];
      const { due_date } = this.user.user_statements.closed_statement;
      if (this.auth_details.permissions.change_first_installment_date) {
        for (let i = 1; i <= 12; i++) {
          dates.push({
            id: i,
            date: moment(due_date, "YYYY-MM-DD")
              .add(i, "month")
              .format("DD/MM/YYYY"),
            selected: false
          });
        }
      } else {
        dates.push({
          id: 1,
          date: moment(due_date, "YYYY-MM-DD")
            .add(1, "month")
            .format("DD/MM/YYYY"),
          selected: false
        });
      }
      dates[0].selected = true;
      this.dueDates = dates;
      return this.$emit("onDateSelect", dates[0].date);
    }
  },
  methods: {
    handleDateClick(dueDate) {
      const dates = this.dueDates.map(item => ({
        ...item,
        selected: item.date === dueDate.date
      }));
      this.dueDates = dates;
      this.$emit("onDateSelect", dueDate.date);
    }
  }
};
</script>

<style lang="scss">
.due-dates-wrapper {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  .el-button + .el-button {
    margin-left: 0;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
